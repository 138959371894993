




import { Component, Vue } from 'vue-property-decorator';
import AuthRegisterForm from '@/app/modules/auth/components/AuthRegisterForm.vue';

@Component({
  components: {
    AuthRegisterForm,
  },
})
export default class AuthRegisterView extends Vue {
}
